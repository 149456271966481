import React from "react";
import Logo from "../assets/images/logo.svg";
const Preloader = () => {
  return (
    <div
      className="fixed inset-0 bg-black flex justify-center items-center "
      style={{ zIndex: 999 }}
    >
      <img src={Logo} alt="logo" width={100} className="logo-shine" />
    </div>
  );
};

export default Preloader;
