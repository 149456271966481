import { createContext, useContext, useReducer } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection,addDoc } from "firebase/firestore/lite";
import StoreReducer from "./StoreReducer";
import actions from "./Actions";
import { initialFirebaseContext } from "../Datatypes/main";
import {
  getAuth,
} from "firebase/auth";
import Swal from "sweetalert2";
const {  SAVE_IN_INFO } = actions;
//firebase project link
const firebaseConfig = {
  apiKey: "AIzaSyAXFQT4lPQUYfYsC_sG-npYRwr7q8kI1Sg",
  authDomain: "wabbit-cards-landing-page.firebaseapp.com",
  projectId: "wabbit-cards-landing-page",
  storageBucket: "wabbit-cards-landing-page.appspot.com",
  messagingSenderId: "122513438594",
  appId: "1:122513438594:web:8c679f4fed881f1e0e819b",
  measurementId: "G-P2GJY57SR0"
};
const firebaseApp = initializeApp(firebaseConfig);
export const useFirebase = () => useContext(FirebaseContext);

const initialContext: initialFirebaseContext = {
  InfoData: [],
  SaveInfo: (data:any) => {},
};

export const FirebaseContext = createContext(initialContext);
export const FirebaseProvider = (props: any) => {
  const [state, dispatch] = useReducer(StoreReducer, initialContext);

  //Save Info Data
  const SaveInfo = (data: any) => {
    const userCollectionRef = collection(db, "formdata");
    return addDoc(userCollectionRef, data)
      .then((value) => {
        dispatch({ payload: value, type: SAVE_IN_INFO });
        Swal.fire("Good job!", "Your data has been submitted!", "success");
        return true;
      })
      .catch((error) => {
        alert(error?.message);
        return false;
      });
  };
  return (
    <FirebaseContext.Provider
      value={{ ...state, SaveInfo }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
//previous
export const db = getFirestore(firebaseApp);
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
