import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
// import Home from "./pages/Home";
import Preloader from "./components/Preloader";
const Home =lazy(()=>import('./pages/Home'))
function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Preloader />}>
              <Home />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
