import actions from "./Actions";
const { SAVE_IN_INFO } =
  actions;

const reducer = (state: any, actions: { payload: any; type: string }) => {
  switch (actions.type) {
    case SAVE_IN_INFO:
      return { ...state, SaveInfo: actions.payload };
    default:
      return state;
  }
};
export default reducer;
